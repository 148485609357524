import React from "react";

import { FaChevronRight, FaArrowRight } from "react-icons/fa6";

export const Hero = ({ lookupRef }) => {

    const scrollToLookup = () => lookupRef.current.scrollIntoView({ behavior: 'smooth' });

    return (
        <>

            <div className="flex flex-col items-center justify-center w-screen overflow-x-hidden pt-[80px] relative w-full">


                <div className="phone:p-[30px] z-20 relative max-w-[80vw] mx-[40px] mb-[100px] mt-[100px] p-[100px] rounded-lg bg-camecobackground-900
                    before:backdrop-blur before:top-[-20px] before:left-[-20px] before:z-[-1] before:content-[''] before:absolute before:w-[calc(100%+40px)] before:h-[calc(100%+40px)] before:bg-white/20 before:opacity-[0.3] before:rounded-lg flex flex-col phone:gap-5 gap-[2vw]">


                    {/* <span className="phone:after:h-[60px] w-fit relative after:z-[-1] after:content-[''] after:w-[calc(100%+20px)] after:absolute after:h-[75px] after:bottom-[-5px] after:left-[-5px] after:bg-black"> */}

                    <div>
                        <p className="font-black text-xl leading-[1]">Cameco</p>

                        <p className="phone:text-4xl text-[6vw] text-white font-bold leading-[1]">
                            Find the right server for 
                            <span 
                                className="ml-[15px] relative text-camecoaccent-500 after:content-[''] after:z-[-1] after:w-full after:absolute after:h-[4px] after:bottom-[8px] after:left-0 after:rounded-sm after:bg-camecoaccent-500"
                                >
                                {/*style={{background: "-webkit-linear-gradient(180deg, var(--text-800) 0%, var(--text-500) 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}*/}
                                you.
                            </span>
                        </p>
                    </div>

                    <button className="w-fit cameco-button flex flex-row items-center rounded-[200px] space-x-1.5 relative py-3 bg-black text-white pl-4 pr-3
                    after:z-[-1] after:content-[''] after:absolute after:w-[calc(100%+8px)] after:h-[calc(100%+8px)] after:bg-camecoopacitytext-50 after:z-[-1] after:left-[-4px] after:rounded-[200px] after:opacity-[0.3]
                    transition-color duration-250
                    focus-visible:outline-none focus-visible:ring focus-visible:ring-black
                    active:scale-95"
                    onClick={scrollToLookup}>
                        <p className="font-medium phone:text-medium text-[1.5vw] text-camecotext-60 leading-[1]">Get Started</p>
                        <div className="phone:w-[8px] w-[1.5vw] h-[10px]"></div>
                        <FaArrowRight className="phone:cameco-icon-2-phone cameco-icon-2 phone:h-[12px] h-[0.8vw] absolute phone:right-[10px] right-[1vw] duration-[200ms] opacity-0" />
                        <FaChevronRight className="phone:cameco-icon-1-phone cameco-icon-1 phone:h-[12px] h-[0.8vw] absolute phone:right-[8px] right-[1vw] duration-[200ms]" />
                    </button>

                    {/* consider adding background to make blur more cooler?? :D */}

                </div>

            </div>

        </>
    )

}