import React, { useEffect, useState } from 'react';
import { Input } from "@nextui-org/input";
import { Slider } from "@nextui-org/slider";
import { Divider } from '@nextui-org/react';

import { FaMemory } from 'react-icons/fa6';

export const DiscordRedirect = () => {
  
  useEffect(() => {

    window.location.href = "https://discord.gg/FY4ESKeeCV";

  }, []);

  return (
    <>
      If you are not redirected, <a href='https://discord.gg/FY4ESKeeCV'>click here</a>.
    </>
  )

};