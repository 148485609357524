import React from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import { NextUIProvider } from '@nextui-org/react';

import { Home } from "./pages/home";
import { Soon } from "./pages/soon";
import { DiscordRedirect } from "./pages/discord";

import "./styles/main.css";

function App() {

  //document.querySelector("body")?.classList.add("dark", "text-foreground", "bg-background-900");

  //localStorage.setItem("theme", "dark");

  const history = useNavigate();

  React.useEffect(() => {
    console.clear();
    console.log('%cHOLD UP THERE!', 'color: #e96665; font-weight: bold;; font-size: 50px');
    console.log(
      "%cIf someone told you to paste something here, it's VERY likely you're being scammed.",
      'color: red; font-size: 20px'
    );
    console.log('%cPasting something here could potentially be very dangerous!', 'color: red; font-size: 25px');
  }, []);

  return (

    <NextUIProvider>
      <main className="dark text-foreground bg-black w-screen h-screen relative overflow-x-hidden scroll-smooth">


        <Routes>
          {/* Routes */}
          <Route path='/' element={<Soon></Soon>} />
          <Route path='/testing' element={<Home></Home>} />

          {/* Redirects */}
          <Route path='/dc' element={<DiscordRedirect />} />
          <Route path='/discord' element={<DiscordRedirect />} />
        </Routes>

      </main>
    </NextUIProvider>
    
  );

};

export default App;
