import React from "react";

import { FaChevronRight, FaArrowRight } from "react-icons/fa6";

export const Navbar = () => {

    return (
        <>

            <div class="w-full fixed h-40 top-0 z-[21] pointer-events-none w-screen overflow-hidden mr-[10px]">
                <div class="w-full backdrop-blur-lg header-blur absolute h-28 top-0 z-[31] pointer-events-none"></div>
                <div class="w-full header-blur backdrop-blur-md absolute h-40 top-0 z-[31] pointer-events-none"></div>
                <div class="w-full header-blur backdrop-blur-sm absolute h-40 top-0 z-[31] pointer-events-none"></div>
            </div>

            <div className="z-[22] w-screen h-[80px] fixed p-[15px] flex flex-row items-center justify-between">

                <div className="flex flex-row gap-2 items-center">
                    <img src="/logo.png" className="h-[50px]" />
                    <p className="text-3xl font-black">Cameco</p>
                </div>

                <div>
                    <button className="cameco-button flex flex-row items-center rounded-[200px] space-x-1.5 font-semibold relative py-2 bg-black text-white pl-4 pr-3
                    after:z-[-1] after:content-[''] after:absolute after:w-[calc(100%+8px)] after:h-[calc(100%+8px)] after:bg-camecoopacitytext-50 after:z-[-1] after:left-[-4px] after:rounded-[200px] after:opacity-[0.3]
                    transition-color duration-250
                    active:scale-95 active:bg-camecosecondary-700">
                        <p className="font-[600] text-camecotext-60 leading-[1]">Discord</p>
                        <div className="w-[8px] h-[10px]"></div>
                        <FaArrowRight className="cameco-icon-2 h-[12px] absolute right-[10px] duration-250 transition-250 opacity-0" />
                        <FaChevronRight className="cameco-icon-1 h-[12px] absolute right-[8px] duration-250 transition-250" />
                    </button>
                </div>

            </div>

        </>
    )

}