import React, { useRef, useState } from 'react';
import { Input } from "@nextui-org/input";
import { Slider } from "@nextui-org/slider";
import { Divider } from '@nextui-org/react';

import { FaMemory } from 'react-icons/fa6';

import { Navbar } from "../components/navbar";
import { Hero } from "../components/hero";

export const Home = () => {

  const lookupRef = useRef(null)
  
  const [isLoading, setIsLoading] = React.useState(false);

  const [ram, setRam] = useState([0]);
  const [ram2, setRam2] = useState([32]);

  if(isNaN(ram)) {
    setRam(0)
  }

  if(ram < 0) {
    setRam(0)
  }

  if(ram > 128) {
    setRam(128)
  }

  if(isNaN(ram2)) {
    setRam2(0)
  }

  if(ram2 < 0) {
    setRam2(0)
  }

  if(ram2 > 128) {
    setRam2(128)
  }

  return (
    <>
      <title>Cameco - Home</title>

      <Navbar />

      <Hero lookupRef={lookupRef} />

      <div className="max-w-[100vw] absolute pointer-events-none inset-x-0 top-[0] z-10 transform-gpu blur-xl scale-[5]" aria-hidden="true"><div className="wierd-polygon relative pointer-events-none left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-camecoaccent-500 to-camecoaccent-600 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"></div></div>

      <div>
        <div className='flex flex-row items-center gap-2'>
          <FaMemory />
          <p>RAM</p>
        </div>

        {/*<div className='flex flex-row w-[200px] items-center gap-2'>

        <Input type="text" value={ram} onChange={e => parseInt(e.target.value) > 128 ? setRam(128) : (parseInt(e.target.value) >= ram2) ? console.log("ruh roh") : setRam(parseInt(e.target.value))} />
        <Divider className='w-[10px]'></Divider>
        <Input type="text" value={ram2} onChange={e => parseInt(e.target.value) > 128 ? setRam2(128) : (parseInt(e.target.value) <= ram) ? console.log("ruh roh") : setRam2(parseInt(e.target.value))} />

        </div>*/}

        <div className='flex flex-row w-[200px] items-center gap-2'>

          <p>{ram} GB</p>
          <Divider className='w-[10px]'></Divider>
          <p>{ram2} GB</p>

        </div>

        <Slider 
          step={1} 
          defaultValue={[0, 32]}
          minValue={0}
          maxValue={128} 
          onChange={(e) => {setRam(e[0]) 
            setRam2(e[1])}}
          value={[ram, ram2]}
          showTooltip={true}
          className="max-w-md"
          classNames={{"track": "!border-x-[0px]"}}
        />
      </div>

    </>
  )

};