import React from 'react';

export const Soon = () => {

  function redirectToDiscord() {

    window.location = "https://discord.cameco.app";

  };

  return (
    <>
      <title>#Cameco - Coming soon</title>

      <div className='bg-camecobackground-900'>

        <div className='w-screen h-screen flex flex-col items-center justify-center relative'>
          <div className='w-fit h-fit relative p-[20px] phone:w-full'>

            <div className='w-[calc(100%-40px)] h-[calc(100%-40px)] orbs absolute'></div>

            <div className='card flex flex-col gap-[10px] items-center p-[50px] rounded-xl z-10'>
              <h1 className='z-20 phone:text-3xl text-5xl leading-[1] font-black'>#Cameco</h1>
              <p className='z-20 phone:text-md text-xl leading-[1]'>Coming soon</p>

              <button onClick={redirectToDiscord} className='z-20 w-fit h-fit p-[10px] bg-camecoaccent-800 rounded-lg flex flex-row items-center gap-1 cursor-pointer hover:bg-camecoaccent-700 transition-colors duration-250 
              active:ring active:ring-camecoaccent-600 active:transition-all active:border-none active:duration-0
              focus:outline-none focus:ring focus:ring-camecoaccent-500 focus:ring-opacity-100 focus:transition-all focus:duration-0'>
                <img src='/discord.png' className='h-[13px] aspect-[5/4]' />
                <p className='text-white text'>Discord</p>
              </button>

            </div>

          </div>

        </div>

      </div>

    </>
  )

};